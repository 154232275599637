import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'

export const PostBody = ({  body, ghostHtml }) => {
    // // add missing closing tag for img 
    // ghostHtml = ghostHtml.replace(/(<img("[^"]*"|[^\/">])*)>/gi, `$1/>`)
    // //add missing closing tag for br
    // ghostHtml = ghostHtml.replace(/(<br("[^"]*"|[^\/">])*)>/gi, `$1/>`)
    // //add missing closing tag for hr
    // ghostHtml = ghostHtml.replace(/(<hr("[^"]*"|[^\/">])*)>/gi, `$1/>`)
    //remove height and width attribute
    ghostHtml = ghostHtml.replace(/(?:height|width)=[^ ]*"/gi, ``)
    //remove kg-card markdown comment -> Embed javascript
    // <!--kg-card-begin: markdown-->
    // <!--kg-card-end: markdown-->
    // ghostHtml = ghostHtml.replace(/<!--kg-card-begin: markdown-->/gi, ``)
    // ghostHtml = ghostHtml.replace(/<!--kg-card-end: markdown-->/gi, ``)
    // //remove breaking down jsx/expression syntax
    // ghostHtml = ghostHtml.replace(/{{/gi, ``)
    // ghostHtml = ghostHtml.replace(/}}/gi, ``)
    return (
        <>
            {/* {body ?
                <MDXProvider components={components}>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
                : 
                ``
            } */}
            
            {/* render it as html instead of mdx component */ }
            {<div
                className="content-body"
                dangerouslySetInnerHTML={{ __html: ghostHtml }}
            />}
        </>
    )
}
